import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ArchivePage = ({ data }) => {
  const names = data.allFile.edges.map(({ node }) => node.name)
  const months = names.reduce(
    (acc, name) => {
      const month = name.split('-')[0]
      acc[month].push(name)
      return acc
    },
    [
      'ian',
      'feb',
      'mar',
      'apr',
      'mai',
      'iun',
      'iul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ].reduce((acc, m) => ({ ...acc, [m]: [] }), {})
  )
  return (
    <Layout>
      <Seo title="Home" />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.entries(months)
          .filter(m => m[1].length)
          .map(([month, pages]) => (
            <ul key={month} style={{ minWidth: 100 }}>
              <h2>{month}</h2>
              {pages.map(page => (
                <li key={page}>
                  <Link to={`/meals/${page}`}>{page.replace('-', ' ')}</Link>
                </li>
              ))}
            </ul>
          ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "meals" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          relativeDirectory
        }
      }
    }
  }
`

export default ArchivePage
